import React, { Component } from 'react';
import './assets/css/not-found.css';
import HeadOver from './assets/js/head-over';
import SiteMeta from './../utilities/site-meta';

class NotFound extends Component {
    componentDidMount() {              
        //Head over or not
        new HeadOver(false);

        SiteMeta.setTitle("Page Not Found", process.env.REACT_APP_APP_SLOGAN);
        SiteMeta.setMetaDescription(process.env.REACT_APP_APP_META);
    }
    
    render() { 
        return ( 
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <div className="text-center not-found">
                            <h2>Page not found</h2>
                            <img src="assets/img/404.svg" alt="Page not found" />
                        </div>  
                    </div>
                </div>
            </div>
        );
    }
}
 
export default NotFound;