import "react-datepicker/dist/react-datepicker.css";
import "./assets/css/date-picker.css";

import React, { Component } from "react";

import ApiManager from "./../utilities/apiManager";
import DatePicker from "react-datepicker";
import Display from "./../utilities/display";
import HeadOver from "./assets/js/head-over";
import Joi from "joi";
import OwlCarousel from "react-owl-carousel";
import SiteMeta from "./../utilities/site-meta";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import checkJQuery from "../utilities/checkJQuery";
import jquery from "jquery";

//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';

//import JoiDate from '@hapi/joi-date'

/* const Joi = require('joi')
    .extend(require('@joi/date')); */

let owl_carousel = require("owl.carousel");
window.fn = owl_carousel;

class Room extends Component {
  state = {
    room: {},
    data: {
      check_in: "",
      check_out: "",
      title: "",
      first_name: "",
      surname: "",
      address: "",
      email: "",
      email_2: "",
      phone: "",
      phone_2: "",
    },
    errors: {},
    serverStatus: null,
    serverMessage: "",
    loading: false,
  };

  schema = Joi.object({
    check_in: Joi.date().required().min("now").label("Check-in date"),
    check_out: Joi.date().required().min("now").label("Check-out date"),
    title: Joi.string().empty(""),
    first_name: Joi.string().max(60).required().label("First name"),
    surname: Joi.string().max(60).required().label("Surname"),
    address: Joi.string().max(300).required().label("Address"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    email_2: Joi.string()
      .empty("")
      .email({ tlds: { allow: false } })
      .label("Alternative email"),
    phone: Joi.string().required(),
    phone_2: Joi.string().empty(""),
  });

  async componentDidMount() {
    window.$ = window.jQuery = jquery;
    checkJQuery();

    //Head over or not
    new HeadOver(false);

    await axios
      .get(ApiManager.url("pages/room/" + this.props.match.params.slug))
      .then((response) => {
        const { data: room } = response.data;
        console.log(room);

        this.setState({ room });

        SiteMeta.setTitle(room.name, "Guesthouse Rooms");
        SiteMeta.setMetaDescription(room.lead);
      })
      .catch((error) => {
        if (error.response) {
          this.props.history.push("/404");
        }
      });
  }

  handleDateChange = (value, field) => {
    console.log(value, field);
    let data = { ...this.state.data };
    data[field] = value;
    console.log(data);

    this.setState({ data });
  };

  handleChange = ({ currentTarget: input }) => {
    let data = { ...this.state.data };
    data[input.name] = input.value;
    console.log(data);

    this.setState({ data });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { room } = this.state;

    const errors = this.validate();
    console.log(errors);
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.setState({ loading: true });

    let data = { ...this.state.data };
    data.check_in = this.state.data.check_in.toISOString().split("T")[0];
    data.check_out = this.state.data.check_out.toISOString().split("T")[0];
    console.log(data);

    const response = await axios.post(
      ApiManager.url("pages/room/" + room.slug + "/reserve"),
      data
    );
    console.log(response.data);

    if (response.data.errors) {
      this.setState({
        serverStatus: "error",
        serverMessage: response.data.errors,
      });
    } else if (response.data.success) {
      this.setState({
        serverStatus: "success",
        serverMessage: response.data.message,
      });
    }

    this.setState({ loading: false });
  };

  validate = () => {
    const { data } = this.state;

    const { error, value } = this.schema.validate(data, { abortEarly: false });

    if (!error) return null;

    let errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  render() {
    const { room, data, errors } = this.state;
    const titles = Display.humanTitles();

    return (
      <React.Fragment>
        {/* <!-- Section Page Title --> */}
        <div className="section">
          <div className="widget-page-title">
            <div
              className="widget-background"
              style={{
                backgroundImage: "url('/assets/img/background-room.jpg')",
              }}
            ></div>
            <div className="wrapper-inner">
              {/* <!-- Title --> */}
              <h5 className="text-uppercase">{room && room.caption}</h5>
              <h1>{room && room.name}</h1>
              <p>{room && room.lead}</p>
              {/* <!-- Title End --> */}
            </div>
          </div>
        </div>
        {/* <!-- Section Page Title End --> */}

        {/* <!-- Section Rooms Detail --> */}
        <div className="section">
          <div className="wrapper-inner">
            <div className="widget-rooms-detail">
              <div className="widget-inner">
                <div className="row">
                  <div className="col-md-8">
                    {/* <!-- Room Slider --> */}
                    <div className="room-slider">
                      <div className="room-price">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: room.prices && room.prices.amount_text,
                          }}
                        />{" "}
                        <small>PER NIGHT</small>
                      </div>
                      {room.photos && (
                        <OwlCarousel
                          className="owl-theme owl-type-room"
                          items={1}
                          loop
                          margin={10}
                          nav
                          dots={false}
                        >
                          {room.photos &&
                            room.photos.map((photo) => (
                              <div key={photo.id}>
                                <img
                                  className="img-responsive"
                                  alt={room.name}
                                  src={room.photo_path + photo.photo}
                                />
                              </div>
                            ))}
                        </OwlCarousel>
                      )}
                    </div>
                    {/* <!-- Room Slider End --> */}

                    {/* <!-- Room Description --> */}
                    <div className="room-desc">
                      <div className="row">
                        <div className="col-xs-12 col-md-6">
                          {/* <!-- Room Features --> */}
                          <div className="room-features">
                            <h5 className="text-uppercase">Features</h5>
                            <h2>Room Features</h2>
                            <ul>
                              {room.features &&
                                room.features.map((feature) => (
                                  <li key={feature.id}>
                                    <i className="fa fa-check"></i>{" "}
                                    {feature.name}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          {/* <!-- Room Features End --> */}
                        </div>
                        <div className="col-xs-12 col-md-6">
                          <h5 className="text-uppercase">Room Description</h5>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: room && room.description_html,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <!-- Room Description End --> */}
                  </div>
                  <div className="col-md-4">
                    {/* <!-- Room Booking --> */}
                    <div className="room-booking">
                      <h5>Reservation</h5>
                      <h2>Reserve Room</h2>
                      <div className="data-form">
                        <form onSubmit={this.handleSubmit}>
                          <div className="row">
                            <div className="col-md-12">
                              <i className="fa fa-calendar-plus-o"></i>
                              <DatePicker
                                name="check_in"
                                placeholderText="CHECK IN"
                                className="datepicker"
                                required
                                autoComplete="off"
                                dropdownMode="select"
                                dateFormat="dd MMM yyyy"
                                selected={data.check_in}
                                onChange={(date) =>
                                  this.handleDateChange(date, "check_in")
                                }
                              />
                              {errors.check_in && (
                                <span className="text-danger">
                                  {errors.check_in}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <i className="fa fa-calendar-plus-o"></i>
                              <DatePicker
                                name="check_out"
                                placeholderText="CHECK OUT"
                                className="datepicker"
                                required
                                autoComplete="off"
                                dropdownMode="select"
                                dateFormat="dd MMM yyyy"
                                selected={data.check_out}
                                onChange={(date) =>
                                  this.handleDateChange(date, "check_out")
                                }
                              />
                              {errors.check_out && (
                                <span className="text-danger">
                                  {errors.check_out}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <i className="fa fa-caret-down"></i>
                              <select
                                name="title"
                                value={data.title}
                                onChange={this.handleChange}
                              >
                                <option value="">Select Title...</option>
                                {Object.keys(titles).map((title, i) => {
                                  return (
                                    <option key={i} value={title}>
                                      {titles[title]}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.title && (
                                <span className="text-danger">
                                  {errors.title}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <input
                                name="first_name"
                                value={data.first_name}
                                onChange={this.handleChange}
                                type="text"
                                placeholder="First name"
                              />
                              {errors.first_name && (
                                <span className="text-danger">
                                  {errors.first_name}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <input
                                name="surname"
                                value={data.surname}
                                onChange={this.handleChange}
                                type="text"
                                placeholder="Surname"
                              />
                              {errors.surname && (
                                <span className="text-danger">
                                  {errors.surname}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <textarea
                                name="address"
                                value={data.address}
                                onChange={this.handleChange}
                                rows={3}
                                placeholder="Address"
                              />
                              {errors.address && (
                                <span className="text-danger">
                                  {errors.address}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <input
                                name="email"
                                value={data.email}
                                onChange={this.handleChange}
                                type="email"
                                placeholder="Email address"
                              />
                              {errors.email && (
                                <span className="text-danger">
                                  {errors.email}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <input
                                name="email_2"
                                value={data.email_2}
                                onChange={this.handleChange}
                                type="email"
                                placeholder="Alternative email (optional)"
                              />
                              {errors.email_2 && (
                                <span className="text-danger">
                                  {errors.email_2}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <input
                                name="phone"
                                value={data.phone}
                                onChange={this.handleChange}
                                type="tel"
                                placeholder="Phone number"
                              />
                              {errors.phone && (
                                <span className="text-danger">
                                  {errors.phone}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <input
                                name="phone_2"
                                value={data.phone_2}
                                onChange={this.handleChange}
                                type="tel"
                                placeholder="Alternative phone (optional)"
                              />
                              {errors.phone_2 && (
                                <span className="text-danger">
                                  {errors.phone_2}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              {/* <button type="submit" className="btn" disabled={this.state.loading}> */}
                              <button type="submit" className="btn">
                                Reserve This Room
                                {this.state.loading && (
                                  <i className="fa fa-spin fa-spinner"></i>
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!-- Room Booking End --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Section Rooms Detail End --> */}

        <SweetAlert
          danger
          show={this.state.serverStatus == "error"}
          title="Error"
          onConfirm={this.hideAlert}
        >
          {this.state.serverMessage}
        </SweetAlert>

        <SweetAlert
          success
          show={this.state.serverStatus == "success"}
          title="Thank you!"
          onConfirm={this.hideAlert}
        >
          {this.state.serverMessage}
        </SweetAlert>
      </React.Fragment>
    );
  }

  hideAlert = () => {
    let { serverStatus, serverMessage } = this.state;

    if (serverStatus == "success") {
      const data = {
        check_in: "",
        check_out: "",
        title: "",
        first_name: "",
        surname: "",
        address: "",
        email: "",
        email_2: "",
        phone: "",
        phone_2: "",
      };
      this.setState({ data });
    } else {
      //
    }

    serverStatus = null;
    serverMessage = "";
    this.setState({ serverStatus, serverMessage });
  };
}

export default Room;
