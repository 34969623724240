import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class NavRight extends Component {
    
    render() { 
        const { activities } = this.props.nav;
        return ( 
            <ul className="nav-right">
                <li>
                    <NavLink to="/facilities">Facilities</NavLink>
                </li>
                <li className="sub">
                    <NavLink to="#" onClick={e => e.preventDefault()}>Activities</NavLink>
                    <ul>
                        { activities && activities.map(activity => <li key={activity.slug}><NavLink to={"/activities/" + activity.slug}>{activity.name}</NavLink></li>)}
                    </ul>
                </li>
                <li><NavLink to="/contact">Contact Us</NavLink></li>
            </ul>
         );
    }
}
 
export default NavRight;