import React, { Component } from 'react';
import jquery from 'jquery';
import checkJQuery from '../utilities/checkJQuery';
import axios from 'axios';
import SiteMeta from './../utilities/site-meta';
import ApiManager from './../utilities/apiManager';
import Facility from '../components/facility';
import HeadOver from './assets/js/head-over';

class Facilities extends Component {
    state = { 
        facilities: [],
        caption: 'Serenity and tranquility',
        intro: 'At ' + process.env.REACT_APP_APP_NAME + ', we offer you more than lodging, here our wide-ranging activities and facilities will make you have a unique farm experience.'
    }

    async componentDidMount() {
        window.$ = window.jQuery = jquery;
        checkJQuery();    

        //Head over or not
        new HeadOver(false);

        await axios.get(ApiManager.url("pages/facilities"))
        .then((response) => {
            const {data: facilities} = response.data;
            console.log(facilities);

            this.setState({facilities});

           SiteMeta.setTitle("Our Facilities", this.state.caption);
           SiteMeta.setMetaDescription(this.state.intro);
        })
        .catch( (error) => {
          if (error.response) {
            this.props.history.push("/404");
          }
        });
    }

    render() { 
        return ( 
            <React.Fragment>
                {/* <!-- Section Page Title --> */}
                <div className="section">
                    <div className="widget-page-title">
                        <div className="widget-background" style={{backgroundImage: "url('/assets/img/background-facilities.jpg')"}}></div>
                        <div className="wrapper-inner">
                            {/* <!-- Title --> */}
                            <h5 className="text-uppercase">{this.state.caption}</h5>
                            <h1>Our Facilities</h1>
                            <p>{this.state.intro}</p>
                            {/* <!-- Title End --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- Section Page Title End --> */}

                {/* <!-- Section Services --> */}
                <div className="section">
                    <div className="wrapper-inner">
                        {/* <!-- Services List --> */}
                        <div className="widget-services-list">
                            { this.state.facilities && this.state.facilities.length > 0 && 
                                this.state.facilities.map(fac => <Facility key={fac.id} facility={fac} imagePath={fac.photo_path} />)
                            }
                        </div>
                        {/* <!-- Services List End --> */}
                    </div>
                </div>
                {/* <!-- Section Services End --> */}
            </React.Fragment>
         );
    }
}
 
export default Facilities;