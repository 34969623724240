import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class Facility extends Component {
    state = {
        photoIndex: 0,
        isOpen: false,
    }
    
    render() { 
        const {facility, imagePath} = this.props;
        const { photoIndex, isOpen } = this.state;
        const images = this.getImages(facility.photos, imagePath);
        console.log(images);

        return ( 
            <React.Fragment>

            <div className="services-item">
                <div className="item-photo">
                    <div className="photo-big" style={{backgroundImage: "url('" + imagePath + facility.photos[0].photo + "')"}}></div>
                    <div className="photo-small" style={{backgroundImage: "url('" + imagePath + facility.photos[1].photo + "')"}}></div>
                    <button className="btn btn-default text-uppercase" onClick={() => this.setState({ isOpen: true })}>More photos</button>
                </div>
                <div className="item-desc">
                    <h5 className="text-uppercase">{facility.caption}</h5>
                    <h2>{facility.name}</h2>
                    <div dangerouslySetInnerHTML={{ __html:  facility && facility.description }} />
                </div>
            </div>

            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                    })
                    }
                    onMoveNextRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                    })
                    }
                />
            )}
            
            </React.Fragment>
         );
    }

    getImages(photos, imgPath){
        let images = [];
        for(let i=0; i<photos.length; i++){
            images[i] = imgPath + photos[i].photo;
        }

        return images;
    }
}
 
export default Facility;