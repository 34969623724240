class Display {
    static humanTitles(){
        return {
            0: 'Mr',
            1: 'Mrs',
            2: 'Ms',
            3: 'Engr',
            4: 'Dr',
            5: 'Chief',
        }
    }
}
 
export default Display;