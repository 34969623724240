import React, { Component } from 'react';
import './assets/css/activity-card.css';
import { Link } from 'react-router-dom';

class ActivityCard extends Component {
    render() { 
        const {activity} = this.props;

        return (  
            <React.Fragment>
                <div className="activity-card">
                    <img src={activity.photo} alt={activity.name} className="img-responsive"/>
                    <div className="text">
                        <p className="heading">{activity.name}</p>
                        <div className="desc">{activity.description}</div>
                        <div className="text-center">
                            <Link className="btn btn-default btn-sm text-uppercase" to={"activities/" + activity.slug}>
                                View details
                            </Link>
                        </div>     
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default ActivityCard;