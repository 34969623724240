import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Joi from 'joi';
import axios from 'axios';
import ApiManager from './../utilities/apiManager';
import SweetAlert from 'react-bootstrap-sweetalert';

class SiteFooter extends Component {
    state = { 
        data: {email: ""},
        errors: {},
        serverStatus: null,
        serverMessage: "",
        loading: false,
    }

    schema = Joi.object({
        email: Joi.string().email({ tlds: {allow: false} }).required().label('Email'),
    });

    handleChange = ({ currentTarget: input }) => {
        let data = { ...this.state.data };
        data[input.name] = input.value;

        this.setState({data});
    }

    handleSubmit = async e => {
        e.preventDefault();

        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) {return}

        this.setState({loading: true});

        const response = await axios.post(ApiManager.url("newsletter"), this.state.data );
        console.log(response.data);
        
        if(response.data.errors){
            this.setState({
                serverStatus: 'error',
                serverMessage: response.data.errors
            });
        }else if(response.data.success){
            this.setState({
                serverStatus: 'success',
                serverMessage: response.data.message,
            });
        }

        this.setState({loading: false});
    }

    validate = () => {     
        const {data} = this.state; 

        const {error, value} = this.schema.validate(data, { abortEarly: false });

        if(!error) return null;

        let errors = {};
        for(let item of error.details) 
            errors[item.path[0]] = item.message;

        return errors;
    }

    render() { 
        const {data, errors, loading} = this.state;

        return ( 
            <React.Fragment>
                {/*<!-- Site Footer -->*/}
                <div className="site-footer"> 
                    {/*<!-- Footer Top -->*/}
                    <div className="footer-top">
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h5>Social Media</h5>
                                    <h6>Follow { process.env.REACT_APP_APP_NAME }</h6>
                                    <div className="widget-social-icons">
                                        <ul>
                                            <li><a href={ process.env.REACT_APP_SOC_FB } target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
                                            <li><a href={ process.env.REACT_APP_SOC_TW } target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a></li>
                                            <li><a href={ process.env.REACT_APP_SOC_LI } target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a></li>
                                            <li><a href={ process.env.REACT_APP_SOC_IG } target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <h5>Newsletter</h5>
                                    <h6>We love to share new offers and exlusive promotions</h6>
                                    <div className="widget-newsletter">
                                        <form onSubmit={this.handleSubmit}>
                                            <input value={data.email} onChange={this.handleChange}  id="footer_email" name="email" type="email" placeholder="ENTER YOUR E-MAIL ADDRESS" required/>
                                            <button type="submit" disabled={loading}>
                                                <i className={loading ? 'fa fa-spin fa-spinner' : 'fa fa-paper-plane'}></i>
                                            </button>
                                            {errors.email && <span className="text-danger text-center">{errors.email}</span>}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<!-- Footer Top End -->*/}
                    {/*<!-- Footer Bottom -->*/}
                    <div className="footer-bottom text-uppercase">
                        <div className="wrapper">
                            <div className="footer-logo">
                                <img src="/assets/img/logo.png" alt={ process.env.REACT_APP_APP_NAME }/>
                            </div>
                            <div className="footer-copyright">
                                <p>Copyright &copy; { process.env.REACT_APP_APP_NAME }</p>
                            </div>
                            <div className="footer-contact">
                                <ul>
                                    <li><i className="fa fa-map-marker"></i> { process.env.REACT_APP_ADDRESS }</li>
                                    <li><i className="fa fa-phone"></i> { process.env.REACT_APP_PHONE }</li>
                                    <li><a href="mailto:{ process.env.REACT_APP_EMAIL }"><i className="fa fa-paper-plane"></i> { process.env.REACT_APP_EMAIL }</a></li>
                                </ul>
                            </div>
                            <div className="footer-nav">
                                <ul>
                                    <li><Link to="/sitemap.xml">Sitemap</Link></li>
                                    <li><Link to="/privacy-policy">Privacy policy</Link></li>
                                    <li><Link to="/terms-of-use">Terms of use</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*<!-- Footer Bottom End -->*/}
                </div>
                {/*<!-- Site Footer End -->*/}

                <SweetAlert
                danger
                show={this.state.serverStatus == 'error'}
                title="Error"
                onConfirm={this.hideAlert}
                >
                {this.state.serverMessage}
                </SweetAlert>

                <SweetAlert
                success
                show={this.state.serverStatus == 'success'}
                title="Thank you!"
                onConfirm={this.hideAlert}
                >
                {this.state.serverMessage}
                </SweetAlert>
            </React.Fragment>
         );
    }

    hideAlert = () => {
        let {serverStatus, serverMessage} = this.state;

        if(serverStatus == 'success'){
            const data = {email: ""};
            this.setState({data});
        }else{
            //
        }

        serverStatus = null; 
        serverMessage = "";
        this.setState({serverStatus, serverMessage});
    }
}
 
export default SiteFooter;