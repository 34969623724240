import React, { Component } from "react";

import HeadOver from "./assets/js/head-over.jsx";
import { Link } from "react-router-dom";
import SiteMeta from "./../utilities/site-meta";

class About extends Component {
  componentDidMount() {
    //Head over or not
    new HeadOver(false);

    SiteMeta.setTitle("About Us", process.env.REACT_APP_APP_SLOGAN);
    SiteMeta.setMetaDescription(process.env.REACT_APP_APP_META);
  }

  render() {
    return (
      <React.Fragment>
        {/* <!-- Section Page Title --> */}
        <div className="section">
          <div className="widget-page-title">
            <div
              className="widget-background"
              style={{
                backgroundImage: "url('/assets/img/background-about.jpg')",
              }}
            ></div>
            <div className="wrapper-inner">
              {/* <!-- Title --> */}
              <h5 className="textuppercase">Your Perfect Escape</h5>
              <p>{process.env.REACT_APP_APP_SLOGAN}</p>
              {/* <!-- Title End --> */}
            </div>
          </div>
        </div>
        {/* <!-- Section Page Title End --> */}

        {/* <!-- Section About Promo --> */}
        <div className="section">
          <div
            className="widget-about-promo"
            style={{ backgroundImage: "url('/assets/img/photo-about.jpg')" }}
          >
            <div className="wrapper-inner">
              <div className="widget-inner">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="text-uppercase">
                      About {process.env.REACT_APP_APP_NAME}
                    </h5>
                    <h2>Your Perfect Escape</h2>
                    <p>
                      {process.env.REACT_APP_APP_NAME} is located within
                      Pinnacles Resource Centre at Km10 Offa-Igbonna Road, Kwara
                      State. The Guesthouse provides a cool, serene and tranquil
                      atmosphere which makes it highly suitable for resting,
                      relaxing, celebrating and being productive. This is an
                      ideal location which incorporates other facilities perfect
                      for Seminars, Conferences, Training Workshops, Reunions,
                      Retreats, Leisure and Weekend / Weekday getaways, etc.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Section About Promo End --> */}

        {/* <!-- Section About Grid --> */}
        <div className="section">
          <div className="widget-about-grid">
            <div className="wrapper-inner">
              <div className="widget-inner">
                <div className="widget-item">
                  <h5 className="text-uppercase">
                    About {process.env.REACT_APP_APP_NAME}
                  </h5>
                  <h2>Our Philosophy</h2>
                  <p>
                    Is that a natural habitat undisturbed nor altered by human
                    activities is refreshing and brings natural healing to
                    achieve total wellness. At our guesthouse and resort, you
                    have facilities to give you a feel of home-away-from-home
                    experience.
                  </p>
                </div>
                <div className="widget-item">
                  <h5 className="text-uppercase">
                    About {process.env.REACT_APP_APP_NAME}
                  </h5>
                  <h2>Our Mission</h2>
                  <p>
                    Is to give you the relaxed and refreshing experience you
                    desire.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Section About Grid End --> */}

        {/* <!-- Section Team --> */}
        <div className="section">
          <div className="widget-team-carousel">
            <div className="wrapper-inner">
              {/* <!-- Team Title --> */}
              <div className="widget-title">
                <h5 className="text-uppercase">Team</h5>
                <h2>Our Staff</h2>
                <p>
                  Meet our management leading a team of unrivalled dedicated
                  people to take care of your hospitality.
                </p>
              </div>
              {/* <!-- Team Title End --> */}
              {/* <!-- Team Carousel --> */}
              <div className="row">
                <div className="col-md-3 col-md-offset-2 team-item">
                  <div className="item-inner">
                    <div className="item-photo">
                      <img
                        src="/assets/img/team/frank-emmanuel.jpg"
                        className="img-responsive"
                        alt="Frank Emmanuel"
                      />
                    </div>
                    <div className="item-desc">
                      <h3>Frank Emmanuel</h3>
                      <h4>Facility Manager</h4>
                      <ul>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-facebook"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-linkedin"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-instagram"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 team-item">
                  <div className="item-inner">
                    <div className="item-photo">
                      <img
                        src="/assets/img/team/mimi-zotoglo.jpg"
                        className="img-responsive"
                        alt="Staff"
                      />
                    </div>
                    <div className="item-desc">
                      <h3>Mimi Zotoglo</h3>
                      <h4>Hospitality Manager</h4>
                      <ul>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-facebook"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-linkedin"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-instagram"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 team-item">
                  <div className="item-inner">
                    <div className="item-photo">
                      <img
                        src="/assets/img/team/team.jpg"
                        className="img-responsive"
                        alt=""
                      />
                    </div>
                    <div className="item-desc">
                      <h3>&nbsp;</h3>
                      <h4>Training Coordinator &amp; Business Manager</h4>
                      <ul>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-facebook"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-linkedin"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-instagram"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-3 team-item">
                                    <div className="item-inner">
                                        <div className="item-photo">
                                            <img src="/assets/img/team/team.jpg" className="img-responsive" alt="team" />
                                        </div>
                                        <div className="item-desc">
                                            <h3>&nbsp;</h3>
                                            <h4>Services Manager</h4>
                                            <ul>
                                                <li><Link to="/about"><i className="fa fa-facebook"></i></Link></li>
                                                <li><Link to="/about"><i className="fa fa-twitter"></i></Link></li>
                                                <li><Link to="/about"><i className="fa fa-linkedin"></i></Link></li>
                                                <li><Link to="/about"><i className="fa fa-instagram"></i></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
              </div>
              {/* <!-- Team Carousel End --> */}
            </div>
          </div>
        </div>
        {/* <!-- Section Team End --> */}
      </React.Fragment>
    );
  }
}

export default About;
