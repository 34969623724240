import React, { Component } from "react";
import HeadOver from "./assets/js/head-over";
import SiteMeta from "./../utilities/site-meta";
import Joi from "joi";
import axios from "axios";
import ApiManager from "./../utilities/apiManager";
import SweetAlert from "react-bootstrap-sweetalert";

class ContactUs extends Component {
  state = {
    sender: { firstName: "", surname: "", email: "", phone: "", message: "" },
    errors: {},
    serverStatus: null,
    serverMessage: "",
    loading: false,
  };

  schema = Joi.object({
    firstName: Joi.string().max(60).required().label("First name"),
    surname: Joi.string().max(60).required().label("Surname"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    //phone: Joi.string().pattern(/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i).label('Phone number'),
    phone: Joi.string().empty(""),
    message: Joi.string().min(30).required().label("Message"),
  });

  componentDidMount() {
    //Head over or not
    new HeadOver(false);

    SiteMeta.setTitle("Contact Us", process.env.REACT_APP_APP_SLOGAN);
    SiteMeta.setMetaDescription(process.env.REACT_APP_APP_META);
  }

  handleChange = ({ currentTarget: input }) => {
    let sender = { ...this.state.sender };
    sender[input.name] = input.value;

    this.setState({ sender });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.setState({ loading: true });

    const response = await axios.post(
      ApiManager.url("contact"),
      this.state.sender
    );
    console.log(response.data);

    if (response.data.errors) {
      this.setState({
        serverStatus: "error",
        serverMessage: response.data.errors,
      });
    } else if (response.data.success) {
      this.setState({
        serverStatus: "success",
        serverMessage: response.data.message,
      });
    }

    this.setState({ loading: false });
  };

  validate = () => {
    const { sender } = this.state;

    const { error, value } = this.schema.validate(sender, {
      abortEarly: false,
    });

    if (!error) return null;

    let errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  render() {
    const { sender, errors } = this.state;

    return (
      <React.Fragment>
        {/* <!-- Section Page Title --> */}
        <div className="section">
          <div className="widget-page-title">
            <div
              className="widget-background"
              style={{
                backgroundImage: "url('/assets/img/background-contact.jpg')",
              }}
            ></div>
            <div className="wrapper-inner">
              {/* <!-- Title --> */}
              <h5 className="text-uppercase">Get In Touch With Us</h5>
              <h1>Contact Us</h1>
              <p>
                Thank you for visiting our website. We'll love to hear from you.
                Should you want to make enquiries, please feel free to get in
                touch through our details below or simply use the Contact Form
                below.
              </p>
              {/* <!-- Title End --> */}
            </div>
          </div>
        </div>
        {/* <!-- Section Page Title End --> */}

        {/* <!-- Section Contact --> */}
        <div className="section">
          <div className="wrapper-inner">
            <div className="row">
              <div className="col-lg-6">
                {/* <!-- Contact Info --> */}
                <div className="widget-contact-info">
                  <ul>
                    <li>
                      <h5 className="text-uppercase">
                        {process.env.REACT_APP_APP_NAME}
                      </h5>
                      <ul>
                        <li>{process.env.REACT_APP_ADDRESS}</li>
                        <li>Phone: {process.env.REACT_APP_PHONE}</li>
                        <li>
                          <a href={"mailto:" + process.env.REACT_APP_EMAIL}>
                            {process.env.REACT_APP_EMAIL}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                {/* <!-- Contact Info End --> */}
              </div>
              <div className="col-lg-6">
                {/* <!-- Contact Form --> */}
                <div className="widget-contact-form">
                  <h5 className="text-uppercase">Contact Form</h5>
                  <p>
                    We are eager to hear from you; please fill in your contact
                    information and one of our staff members will contact you
                    shortly.
                  </p>
                  <div className="data-form">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            value={sender.firstName}
                            onChange={this.handleChange}
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="YOUR FIRST NAME"
                          />
                          {errors.firstName && (
                            <span className="text-danger">
                              {errors.firstName}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <input
                            value={sender.surname}
                            onChange={this.handleChange}
                            type="text"
                            id="surname"
                            name="surname"
                            placeholder="YOUR SURNAME"
                          />
                          {errors.surname && (
                            <span className="text-danger">
                              {errors.surname}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <input
                            value={sender.email}
                            onChange={this.handleChange}
                            type="text"
                            id="email"
                            name="email"
                            placeholder="YOUR EMAIL ADDRESS"
                          />
                          {errors.email && (
                            <span className="text-danger">{errors.email}</span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <input
                            value={sender.phone}
                            onChange={this.handleChange}
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="YOUR PHONE"
                          />
                          {errors.phone && (
                            <span className="text-danger">{errors.phone}</span>
                          )}
                        </div>
                        <div className="col-md-12">
                          <textarea
                            value={sender.message}
                            onChange={this.handleChange}
                            cols="6"
                            rows="8"
                            id="message"
                            name="message"
                            placeholder="YOUR MESSAGE"
                          ></textarea>
                          {errors.message && (
                            <span className="text-danger">
                              {errors.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <p className="note">
                            * Please fill in all of the required fields
                          </p>
                        </div>
                        <div className="col-md-6 align-right">
                          <button
                            type="submit"
                            className="btn"
                            disabled={this.state.loading}
                          >
                            SEND FORM
                            {this.state.loading && (
                              <i className="fa fa-spin fa-spinner"></i>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <!-- Contact Form End --> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Section Contact End --> */}

        <SweetAlert
          danger
          show={this.state.serverStatus == "error"}
          title="Error"
          onConfirm={this.hideAlert}
        >
          {this.state.serverMessage}
        </SweetAlert>

        <SweetAlert
          success
          show={this.state.serverStatus == "success"}
          title="Thank you!"
          onConfirm={this.hideAlert}
        >
          {this.state.serverMessage}
        </SweetAlert>
      </React.Fragment>
    );
  }

  hideAlert = () => {
    let { serverStatus, serverMessage } = this.state;

    if (serverStatus == "success") {
      const sender = {
        firstName: "",
        surname: "",
        email: "",
        phone: "",
        message: "",
      };
      this.setState({ sender });
    } else {
      //
    }

    serverStatus = null;
    serverMessage = "";
    this.setState({ serverStatus, serverMessage });
  };
}

export default ContactUs;
