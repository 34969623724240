import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SiteMeta from './../utilities/site-meta';
import jquery from 'jquery';
import ApiManager from './../utilities/apiManager';
import checkJQuery from '../utilities/checkJQuery';
import OwlCarousel from 'react-owl-carousel';
import HeadOver from './assets/js/head-over.jsx';
import HomeRoomCard from './../components/homeRoomCard';
import HomeFacilityCard from '../components/homeFacilityCard';

class Home extends Component {
    state = { 
        resources: {},
     }

    async componentDidMount() {
        window.$ = window.jQuery = jquery;
        checkJQuery();

        //Head over or not
        new HeadOver(true);

        await axios.get( ApiManager.url("pages/home"))
        .then((response) => {
            const {data: resources} = response;
            console.log(resources);

            this.setState({resources});

            console.log(process.env.NODE_ENV, process.env.REACT_APP_API_URL);

           SiteMeta.setTitle("Welcome", process.env.REACT_APP_APP_SLOGAN);
           SiteMeta.setMetaDescription(process.env.REACT_APP_APP_META);
        })
        .catch( (error) => {
          if (error.response) {
            this.props.history.push("/404");
          }
        });
    }

    render() { 
        const { rooms, room_photo_path, facilities, facility_photo_path } = this.state.resources;

        return ( 
            <React.Fragment>
                <div className="section">
                    <div className="widget-slider">
                        <div className="wrapper-full">
                        
                            <OwlCarousel className="widget-carousel home-slider owl-carousel owl-theme" items={1} loop margin={0} nav dots={false}>
                                <div className="slider-item" style={{backgroundImage: "url('/assets/img/photo-slide-1.jpg')"}}>
                                    <div className="wrapper">
                                        <div className="item-inner">
                                            <h5 className="text-uppercase">Welcome To {process.env.REACT_APP_APP_NAME}</h5>
                                            <h1>Experience the Freedom</h1>
                                            <h2>in Style</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="slider-item" style={{backgroundImage: "url('/assets/img/photo-slide-2.jpg')"}}>
                                    <div className="wrapper">
                                        <div className="item-inner">
                                            <h5 className="text-uppercase">Welcome To {process.env.REACT_APP_APP_NAME}</h5>
                                            <h1>A Place to Relax</h1>
                                            <h2>in Serene Place</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="slider-item" style={{backgroundImage: "url('/assets/img/photo-slide-3.jpg')"}}>
                                    <div className="wrapper">
                                        <div className="item-inner">
                                            <h5 className="text-uppercase">Welcome To {process.env.REACT_APP_APP_NAME}</h5>
                                            <h1>Touch The Sky</h1>
                                            <h2>in your mind</h2>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>

                {/* <!-- Section Rooms --> */}
                <div className="section">
                    <div className="widget-rooms-carousel top-over">
                        <div className="wrapper-inner">
                            {/* <!-- Rooms Title --> */}
                            <div className="widget-title">
                                <h5>OUR ROOMS</h5>
                                <h2>Experience The Freedom</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eget commodo orci. Integer varius nibh eu mattis porta. Pellentesque dictum sem eget cursus semper. Nullam quis blandit lorem.</p>
                            </div>
                            {/* <!-- Rooms Title End --> */}
                            {/* <!-- Rooms Carousel --> */}
                            
                            {rooms && 
                            <OwlCarousel className="widget-carousel owl-carousel owl-theme" loop={false} items={rooms.length >= 3 ? 3 : rooms.length} margin={10} nav={false} dots={true}>
                                {rooms.map((room) => <HomeRoomCard key={room.id} room={room} photoPath={room_photo_path} />)}
                            </OwlCarousel>
                            }
                            {/* <!-- Rooms Carousel End --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- Section Rooms End --> */}

                {/* <!-- Section About Promo --> */}
                <div className="section">
                    <div className="widget-about-promo" style={{backgroundImage: "url('/assets/img/photo-about.jpg')"}}>
                        <div className="wrapper-inner">
                            <div className="widget-inner">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5 className="text-uppercase">About {process.env.REACT_APP_APP_NAME}</h5>
                                        <h2>Your Perfect Escape</h2>
                                        <p>Pinnacles Guesthouse is located within Pinnacles Resource Centre at Km10 Offa-Igbonna Road, Kwara State. The Guesthouse provides a cool, serene and tranquil atmosphere which makes it highly suitable for resting, relaxing, celebrating and being productive. This is an ideal location which incorporates other facilities perfect for Seminars, Conferences, Training Workshops, Reunions, Retreats, Leisure and Weekend / Weekday getaways, etc. </p>
                                        <Link to="/about" className="btn">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Section About Promo End --> */}

                {/* <!-- Section Features --> */}
                <div className="section">
                    <div className="widget-features-grid">
                        <div className="wrapper-inner">
                            {/* <!-- Features Title --> */}
                            <div className="widget-title">
                                <h5 className="text-uppercase">Our Facilities</h5>
                                <h2>Explore {process.env.REACT_APP_APP_NAME}</h2>
                                <p>At {process.env.REACT_APP_APP_NAME}, we offer you more than lodging, here our wide-ranging activities and facilities will make you have a unique farm experience.</p>
                            </div>
                            {/* <!-- Features Title End --> */}
                            {/* <!-- Features Content --> col-lg-4 col-sm-6 */}
                            <div className="widget-inner">
                                    {facilities && 
                                    <div className="row">
                                        {facilities.map(facility => <div className="col-lg-4 col-sm-6" key={facility.id}><HomeFacilityCard facility={facility} photoPath={facility_photo_path} /></div>)}         
                                    </div>
                                    }                                    
                            </div>
                            {/* <!-- Features Content End --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- Section Features End --> */}
            
            </React.Fragment>
         );
    }
}
 
export default Home;