import axios from 'axios';
import React, { Component } from 'react'
import SiteMeta from '../utilities/display';
import ApiManager from '../utilities/apiManager';
import Lightbox from 'react-image-lightbox';
import ActivityCard from '../components/activityCard';
import HeadOver from './assets/js/head-over';

class Activities extends Component {
    state = { 
        activity: {},
        images: [],
        photoIndex: 0,
        isOpen: false,
        activities: [],
     }

    async componentDidMount() {      
        //Head over or not
        new HeadOver(false);

        await axios.get( ApiManager.url("pages/activity/" + this.props.match.params.slug))
        .then((response) => {
            const {data: activity} = response.data;
            console.log(activity);

            const images = this.getImages(activity.photos, activity.photo_path);

            this.setState({activity, images});

           SiteMeta.setTitle(activity.name, "Guesthouse Activities");
           SiteMeta.setMetaDescription(activity.lead);

           
        })
        .catch( (error) => {
          if (error.response) {
            this.props.history.push("/404");
          }
        });

        // Related activities
        const {data: activities} = await axios.get(ApiManager.url('pages/activity/card/' + this.state.activity.slug));
        console.log(activities.data, typeof activities);
        this.setState({activities: activities.data});
    }
    
    render() { 
        const {activity, images, photoIndex, isOpen, activities } = this.state;
        

        return (  
            <React.Fragment>
                {/* <!-- Section Page Title --> */}
                <div className="section">
                    <div className="widget-page-title">
                        <div className="widget-background" style={{backgroundImage: "url('/assets/img/background-activity.jpg')"}}></div>
                        <div className="wrapper-inner">
                            {/* <!-- Title --> */}
                            <h5 className="text-uppercase">{activity && activity.caption}</h5>
                            <h1>{activity && activity.name}</h1>
                            <p>{activity && activity.lead}</p>
                            {/* <!-- Title End --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- Section Page Title End --> */}

                {/* <!-- Section Blog Single --> */}
                <div className="section">
                    <div className="wrapper-inner">
                        <div className="row">
                            <div className="col-md-9">
                                {/* <!-- Blog Single --> */}
                                <div className="widget-blog-single">
                                    {/* <!-- Single Media --> */}
                                    {activity.photos && 
                                    <div className="single-media" onClick={() => this.setState({ isOpen: true })}>
                                        <div className="media-photo">
                                            <img src={activity.photo_path + activity.photos[0].photo} className="img-responsive" alt={activity.name}/>
                                        </div>
                                    </div>
                                    }
                                    {/* <!-- Single Media End --> */}
                                    {/* <!-- Single Detail --> */}
                                    <div className="single-detail">
                                        <div className="detail-content">
                                            <div dangerouslySetInnerHTML={{ __html: activity && activity.description_html}}/>

                                            {activity.photos && activity.photos.length > 1 &&
                                            <div className="text-center">
                                                <button className="btn btn-default text-uppercase mt-5"  onClick={() => this.setState({ isOpen: true })}>
                                                    More Photos
                                                </button>
                                            </div>
                                            }

                                        </div>
                                    </div>
                                    {/* <!-- Single Detail End --> */}
                                </div>
                                {/* <!-- Blog Single End --> */}

                                {isOpen && (
                                    <Lightbox
                                        mainSrc={images[photoIndex]}
                                        nextSrc={images[(photoIndex + 1) % images.length]}
                                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + images.length - 1) % images.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + 1) % images.length,
                                        })
                                        }
                                    />
                                )}
                            </div>
                            <div className="col-md-3">
                                <h3>Other Activities</h3>

                                {activities  && 
                                    activities.map(act => <ActivityCard key={act.id} activity={act}/>)
                                }
                                        
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Section Blog Single End --> */}
            </React.Fragment>
        );
    }

    getImages(photos, imgPath){
        let images = [];
        for(let i=0; i<photos.length; i++){
            images[i] = imgPath + photos[i].photo;
        }

        return images;
    }
    
}
 
export default Activities;