class SiteMeta {
    static setTitle(name, section){
        const title = name + " - " + section + " | " + process.env.REACT_APP_APP_NAME;
        document.title = title;
    }

    static setMetaDescription(desc){
        const el = document.querySelector("meta[name='description']");
        el.setAttribute('content',desc)
    }
}
 
export default SiteMeta;