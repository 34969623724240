import React, { Component } from "react";
//import jquery from 'jquery';
import SiteHeader from "./components/siteHeader";
import SiteFooter from "./components/siteFooter";
import { Redirect, Route, Switch } from "react-router-dom";
import Home from "./pages/home";
import NotFound from "./pages/notFound";
import About from "./pages/about";
import Room from "./pages/room";
import Restaurant from "./pages/restaurant";
import Facilities from "./pages/facilities";
import Activities from "./pages/activities";
import ContactUs from "./pages/contact";
import Reservation from "./pages/reservation";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <SiteHeader />
        <div className="site-main">
          <Switch>
            <Route path="/about" component={About} />
            <Route path="/rooms/:slug" component={Room} />
            <Route path="/room/reservation/:code?" component={Reservation} />
            <Route path="/restaurants/:slug" component={Restaurant} />
            <Route path="/facilities" component={Facilities} />
            <Route path="/activities/:slug" component={Activities} />
            <Route path="/contact" component={ContactUs} />
            <Route path="/404" component={NotFound} />
            <Route path="/" exact component={Home} />
            <Redirect to="/404" />
          </Switch>
        </div>
        <SiteFooter />
      </React.Fragment>
    );
  }
}

export default App;
