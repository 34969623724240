import React, { Component } from "react";

import ApiManager from "../utilities/apiManager";
import HeadOver from "./assets/js/head-over";
import Joi from "joi";
import { Link } from "react-router-dom";
import SiteMeta from "../utilities/site-meta";
import axios from "axios";
import checkJQuery from "../utilities/checkJQuery";
import jquery from "jquery";

class Reservation extends Component {
  state = {
    searched: false,
    code: "",
    reservation: "",
    room: "",
    booking: "",
    data: { code: "" },
    errors: {},
    serverStatus: null,
    serverMessage: "",
    vatPrice: "",
    finalPrice: "",
    loading: false,
  };

  schema = Joi.object({
    code: Joi.string().required(),
  });

  componentDidMount() {
    window.$ = window.jQuery = jquery;
    checkJQuery();

    //Head over or not
    new HeadOver(false);

    SiteMeta.setTitle("Reservation", "Guesthouse Room");

    const code = this.props.match.params.code;
    this.setState({ code });

    if (code != null) {
      console.log(code);
      this.getReservation(code);
    }
  }

  handleChange = ({ currentTarget: input }) => {
    let data = { ...this.state.data };
    data[input.name] = input.value;
    console.log(data);

    this.setState({ data });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    console.log(errors);
    this.setState({ errors: errors || {} });
    if (errors) return;

    const { code } = this.state.data;
    this.getReservation(code);
  };

  validate = () => {
    const { data } = this.state;

    const { error, value } = this.schema.validate(data, { abortEarly: false });

    if (!error) return null;

    let errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  render() {
    let {
      searched,
      code,
      booking,
      room,
      reservation,
      data,
      errors,
      serverMessage,
      vatPrice,
      finalPrice,
    } = this.state;

    return (
      <React.Fragment>
        {/* <!-- Section Page Title --> */}
        <div className="section">
          <div className="widget-page-title">
            <div
              className="widget-background"
              style={{
                backgroundImage: "url('/assets/img/background-room.jpg')",
              }}
            ></div>
            <div className="wrapper-inner">
              {/* <!-- Title --> */}
              <h5 className="text-uppercae">
                STAY AT {process.env.REACT_APP_APP_NAME}{" "}
              </h5>
              <h1>Room Reservation</h1>
              <p>
                More Than Lodging, Enjoy A Unique Farm House Experience - Your
                Perfect Escape
              </p>
              {/* <!-- Title Emd --> */}
            </div>
          </div>
        </div>
        {/* <!-- Section Page Title End --> */}

        {/* <!-- Section Booking --> */}
        <div className="section">
          <div className="widget-booking-form">
            <div className="wrapper-inner">
              <div className="widget-inner">
                <div className="row">
                  <div className="col-lg-8 col-md-7">
                    {!code && (
                      <div className="booking-form">
                        <h5 className="text-uppercase">Room Reservation</h5>
                        <h2>Reservation Code</h2>
                        <p>
                          Enter the reservation code sent to you via email below
                        </p>
                        <div className="data-form">
                          <form id="booking-form" onSubmit={this.handleSubmit}>
                            <div className="row">
                              <div className="col-md-7">
                                <input
                                  name="code"
                                  value={data.code}
                                  onChange={this.handleChange}
                                  type="text"
                                  placeholder="Reservation code"
                                />
                                {errors.code && (
                                  <span className="text-danger">
                                    {errors.code}
                                  </span>
                                )}
                              </div>
                              <div className="col-md-5">
                                <button type="submit" className="btn btn-block">
                                  Get reservation details
                                  {this.state.loading && (
                                    <i className="fa fa-spin fa-spinner"></i>
                                  )}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}

                    {searched && !code && (
                      <div
                        className="alert alert-danger"
                        style={{ margin: "40px auto" }}
                      >
                        <h3 className="text-center">{serverMessage}</h3>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-5">
                    {code && (
                      <div className="booking-detail">
                        <h5 className="text-uppercase">Reservation details</h5>
                        <h2>Selected Room</h2>
                        <div className="detail-room">
                          <div className="room-photo">
                            {room && (
                              <Link to={"/rooms/" + room.slug}>
                                <img
                                  className="img-responsive"
                                  src={
                                    room.photo_avatar_path +
                                    room.photos[0].photo
                                  }
                                />
                              </Link>
                            )}
                          </div>
                          <div className="room-desc">
                            <h3>
                              <a href="rooms-detail.html">{room.name}</a>
                            </h3>
                            <h4
                              dangerouslySetInnerHTML={{
                                __html: room && room.prices.amount_text,
                              }}
                            />
                          </div>
                        </div>
                        <div className="detail-info">
                          <ul>
                            <li>
                              <label>CHECK IN</label>
                              <p>{reservation.check_in_string}</p>
                            </li>
                            <li>
                              <label>CHECK OUT</label>
                              <p>{reservation.check_out_string}</p>
                            </li>
                            <li>
                              <label>Name</label>
                              <p>{reservation.name}</p>
                            </li>
                            <li>
                              <label>VAT (7.5%)</label>
                              <p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: room && room.prices.currency_symbol,
                                  }}
                                />
                                {Number(vatPrice).toFixed(2).toLocaleString()}
                              </p>
                            </li>
                            <li className="total">
                              <label>TOTAL PRICE</label>
                              <p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: room && room.prices.currency_symbol,
                                  }}
                                />
                                {Number(finalPrice).toFixed(2).toLocaleString()}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                    {/* <!-- Booking Help --> */}
                    <div className="booking-help">
                      <h5>HELP</h5>
                      <h2>Need Reservation Help?</h2>
                      <p>
                        If you need help with reservations, Our team is at your
                        service to help you.
                      </p>
                      <p>
                        <a href={"mailto:" + process.env.REACT_APP_EMAIL}>
                          {process.env.REACT_APP_EMAIL}
                        </a>
                      </p>
                      <h3>
                        <i className="fa fa-phone-square"></i>
                        {process.env.REACT_APP_PHONE}
                      </h3>
                    </div>
                    {/* <!-- Booking Help End --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Section Booking End --> */}
      </React.Fragment>
    );
  }

  getReservation = async (code) => {
    console.log(code);

    this.setState({ loading: true });

    await axios
      .get(ApiManager.url("pages/room/reservation/" + code))
      .then((response) => {
        console.log(response.data);

        const booking = response.data.booking;
        const reservation = response.data.reservation;
        const room = response.data.room;
        const searched = true;

        let vat = 7.5 / 100;
        let vatPrice = response.data.room.prices.amount * vat;
        let finalPrice = response.data.room.prices.amount + vatPrice;

        this.setState({
          booking,
          reservation,
          room,
          searched,
          vatPrice,
          finalPrice,
        });

        console.log(this.state);

        SiteMeta.setMetaDescription(room.lead);
      })
      .catch((error) => {
        if (error.response) {
          //this.props.history.push("/404");
          console.log(error.response);

          const { status } = error.response;
          let { serverMessage } = this.state;

          if (status == 404) {
            serverMessage = "No reservation found";
          } else {
            serverMessage = "Request not understood";
          }

          this.setState({ serverMessage, searched: true });
        }
      });

    this.setState({ loading: false });
  };
}

export default Reservation;
