import axios from 'axios';
import React, { Component } from 'react'
import ApiManager from './../utilities/apiManager';
import SiteMeta from './../utilities/site-meta';
import OwlCarousel from 'react-owl-carousel';
import checkJQuery from '../utilities/checkJQuery';
import jquery from 'jquery'
import HeadOver from './assets/js/head-over';

class Restaurant extends Component {
    state = { 
        restaurant: {},
     }

    async componentDidMount() {
        window.$ = window.jQuery = jquery;
        checkJQuery(); 

        //Head over or not
        new HeadOver(false);

        await axios.get( ApiManager.url("pages/restaurant/" + this.props.match.params.slug))
        .then((response) => {
            const {data: restaurant} = response.data;
            console.log(restaurant);

            this.setState({restaurant});

           SiteMeta.setTitle(restaurant.name, "Restaurants");
           SiteMeta.setMetaDescription(restaurant.lead);
        })
        .catch( (error) => {
          if (error.response) {
            this.props.history.push("/404");
          }
        });
    }
    
    render() { 
        const {restaurant} = this.state;

        return ( 
            <React.Fragment>
                {/* <!-- Section Page Title --> */}
                <div className="section">
                    <div className="widget-page-title">
                        <div className="widget-background" style={{backgroundImage: "url('/assets/img/background-restaurant.jpg')"}}></div>
                        <div className="wrapper-inner">
                            {/* <!-- Title --> */}
                            <h5 className="text-uppercase">{restaurant && restaurant.caption}</h5>
                            <h1>{restaurant && restaurant.name}</h1>
                            <p>{restaurant && restaurant.lead}</p>
                            {/* <!-- Title End --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- Section Page Title End --> */}

                {/* <!-- Section restaurants Detail --> */}
                <div className="section">
                    <div className="wrapper-inner">
                        <div className="widget-rooms-detail">
                            <div className="widget-inner">
                                <div className="row">
                                    <div className="col-md-8">
                                        {/* <!-- restaurant Slider --> */}
                                        <div className="room-slider">
                                            {restaurant.photos && 
                                            <OwlCarousel className='owl-theme' items={1} loop margin={10} nav={false} dots={false}>
                                                {restaurant.photos && restaurant.photos.map(photo => 
                                                    <div key={photo.id}>
                                                        <img className="img-responsive" alt={restaurant.name} src={restaurant.photo_path + photo.photo} />
                                                    </div>
                                                    )}
                                            </OwlCarousel>
                                            }
                                        </div>
                                        {/* <!-- restaurant Slider End --> */}
                                        {/* <!-- restaurant Description --> */}
                                        <div className="room-desc">
                                            <h5 className="text-uppercase">Description</h5>
                                            <div dangerouslySetInnerHTML={{ __html: restaurant && restaurant.description_html}}/>
                                        </div>
                                        {/* <!-- restaurant Description End --> */}
                                    </div>
                                    <div className="col-md-4">
                                        {/* <!-- restaurant menus --> */}
                                        <div className="room-features">
                                            <h5 className="text-uppercase">Menus</h5>
                                            <h2>Available Menus</h2>
                                            <ul>
                                                {restaurant.menus && restaurant.menus.map(menu =>  <li key={menu.id}><i className="fa fa-cutlery"></i> {menu.name}</li>)}
                                                
                                            </ul>
                                        </div>
                                        {/* <!-- restaurant Features End --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Section restaurants Detail End --> */}
            </React.Fragment>
        );
    }
}
 
export default Restaurant;