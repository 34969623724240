import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import NavLeft from './menus/navLeft';
import NavRight from './menus/navRight';
import axios from 'axios';

class SiteHeader extends Component {
    state = {
        nav: []
    }

    async componentDidMount() {
        const { data: nav} = await axios.get(process.env.REACT_APP_API_URL + "nav");
        this.setState({nav});
    }

    render() {
        return ( 
            <React.Fragment>
               {/* <!-- Site Header -->*/}
                <div className="site-header" id="siteHeader">
                    {/*<!-- Header Top -->*/}
                    <div className="header-top">
                        <div className="wrapper">
                            <div className="header-contact">
                                <ul>
                                    <li><i className="fa fa-phone text-muted"></i> {process.env.REACT_APP_PHONE}</li>
                                </ul>
                            </div>
                            <div className="header-lang">
                                <ul>
                                    <li><a href={ process.env.REACT_APP_SOC_FB } target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href={ process.env.REACT_APP_SOC_TW } target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href={ process.env.REACT_APP_SOC_LI } target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a></li>
                                    <li><a href={ process.env.REACT_APP_SOC_IG } target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*<!-- Header Top End -->*/}
                    {/*<!-- Header Bottom -->*/}
                    <div className="header-bottom">
                        <div className="wrapper">
                            <div className="header-logo">
                                <Link to='/'>
                                    <img src="/assets/img/logo.png" alt={process.env.REACT_APP_APP_NAME} />
                                </Link>
                            </div>
                            <div className="header-nav">
                                <NavLeft nav={this.state.nav} />
                                <NavRight nav={this.state.nav} />
                            </div>
                            <div className="header-toggle">
                                <i className="fa fa-bars"></i>
                            </div>
                        </div>
                    </div>
                    {/*<!-- Header Bottom End -->*/}
                </div>
                {/*<!-- Site Header End -->*/}
            </React.Fragment>
         );
    }
}
 
export default SiteHeader;