import React, { Component } from "react";

import { Link } from "react-router-dom";

class HomeFacilityCard extends Component {
  render() {
    const { facility, photoPath } = this.props;
    return (
      <Link to="/facilities">
        <div
          className="features-item"
          style={
            facility.photos !== null
              ? {
                  backgroundImage:
                    "url('" + photoPath + facility.photos.photo + "')",
                }
              : { backgroundImage: "url('" + photoPath + "default.jpg')" }
          }
        >
          <div className="text">
            <h3>{facility.name}</h3>
            <p>{facility.description}</p>
          </div>
        </div>
      </Link>
    );
  }
}

export default HomeFacilityCard;
