import React, { Component } from 'react'
import { Link } from 'react-router-dom';


class HomeRoomCard extends Component {
    render() { 
        const {room, photoPath} = this.props;

        return (
            <React.Fragment>
                <div className="rooms-item">
                    <div className="item-inner">
                        <div className="item-photo">
                            <Link to={"/rooms/" + room.slug} style={{backgroundImage: "url('" + photoPath + room.photo.photo + "')"}}></Link>
                        </div>
                        <div className="item-desc">
                            <h2><Link to={"/rooms/" + room.slug}>{room.name}</Link></h2>
                            <h3 dangerouslySetInnerHTML={{ __html:  room.price && room.price.amount_text }} />
                            <p>{room.lead}</p>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default HomeRoomCard;