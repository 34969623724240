import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class NavLeft extends Component {
    render() { 
        const { rooms, restaurants} = this.props.nav;

        return ( 
            <ul className="nav-left">
                <li><NavLink to="/about">About Us</NavLink></li>
                <li className="sub">
                    <NavLink to="#" onClick={e => e.preventDefault()}>Rooms</NavLink>
                    <ul>
                        { rooms && rooms.map(room => <li key={room.slug}><NavLink to={"/rooms/" + room.slug}>{room.name}</NavLink></li>) }
                    </ul>
                </li>
                <li className="sub">
                    <NavLink to="#" onClick={e => e.preventDefault()}>Restaurants</NavLink>
                    <ul>
                        { restaurants && restaurants.map(restaurant => <li key={restaurant.slug}><NavLink to={"/restaurants/" + restaurant.slug}>{restaurant.name}</NavLink></li>)}
                    </ul>
                </li>
            </ul>
         );
    }
}
 
export default NavLeft;