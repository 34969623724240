/* document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
        let element = document.getElementById("siteHeader") 
        element.classList.add("header-over");
    }
}; */
/* let stateCheck = setInterval(() => {
    if (document.readyState === 'complete') {
      clearInterval(stateCheck);
      let element = document.getElementById("siteHeader");
      element && element.classList.add("header-over");
    }
  }, 100); */

class HeadOver {
  constructor(head){
    if(head){
      this.hasHead();
    }else{
      this.hasNoHead();
    }
  }

  hasHead(){
    let stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        let element = document.getElementById("siteHeader");
        element && element.classList.add("header-over");
      }
    }, 100);
  }

  hasNoHead(){
    let stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        let element = document.getElementById("siteHeader");
        element && element.classList.remove("header-over");
      }
    }, 100);
  }
}
 
export default HeadOver;